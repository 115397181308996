import {
  Button,
  ButtonVariant,
} from '@amzn/stencil-react-components/esm/button';
import { Input, InputFooter } from '@amzn/stencil-react-components/esm/form';
import { Col, Hr, Row, View } from '@amzn/stencil-react-components/esm/layout';
import { Link } from '@amzn/stencil-react-components/esm/link';
import { H3, Label, P, Text } from '@amzn/stencil-react-components/esm/text';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { LOGIN_FORM_STATES, useLoginStore } from '../../../context/loginContext';
import { A2Z_WELCOME_URI } from '../constants';
import { useTranslation } from 'react-i18next';
import PasswordEyeHidden from '../../../assets/password-eye-hidden.svg'
import PasswordEyeUnhidden from '../../../assets/password-eye-unhidden.svg'
import { GetWebauthnChallengeAnswer, getCustomerConfig } from '../../../globals/utils';
import { CognitoUser } from 'amazon-cognito-identity-js';
import ExclamationCircle from '../../../assets/exclamation-circle-small.svg';
import {FAILED_TO_GET_WEBAUTHN_CHALLENGE_ANSWER, NOT_ALLOWED_ERROR } from '../../../globals/constants';
import { ChallengeUser, CustomerConfig, IdPrismUser } from '../../../globals/types';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/esm/message-banner';
import { useWebauthnIcons, useWebauthnStrings } from '../../../hooks/webauthnHooks';
import { useFailoverStore } from '../../../context/failoverContext';

const COGNITO_SMS_MFA_CHALLENGE_LABEL = 'SMS_MFA';
const COGNITO_NEW_PASSWORD_REQUIRED_CHALLENGE_LABEL = 'NEW_PASSWORD_REQUIRED';
const PASSWORD_ATTEMPTS_EXCEEDED = 'Password attempts exceeded';
const INCORRECT_USERNAME_OR_PASSWORD = 'Incorrect username or password.';
const LIMIT_EXCEEDED_EXCEPTION = 'LimitExceededException';
const PASSWORD_HIDDEN_ARIA = 'resources:passwordHidden';
const PASSWORD_UNHIDDEN_ARIA = 'resources:passwordUnhidden';

export default function UsernameStep() {
  const { t } = useTranslation();

  const username = useLoginStore((state) => state.username);
  const password = useLoginStore((state) => state.password);
  const firstTimeLogin = useLoginStore((state) => state.firstTimeLogin);
  const setIntermediateUser = useLoginStore((state) => state.setIntermediateUser);
  const setCognitoUser = useLoginStore((state) => state.setCognitoUser);
  const setLoginFormState = useLoginStore((state) => state.setLoginFormState);
  // need to store the password in state to resend MFA code https://github.com/aws-amplify/amplify-js/issues/6676
  const setPassword = useLoginStore((state) => state.setPassword);
  const promptRegisterWebauthn = useLoginStore((state) => state.promptRegisterWebauthn);
  const setUsername = useLoginStore((state) => state.setUsername);
  const errorRedirecting = useLoginStore((state) => state.errorRedirecting);
  const webauthnEnbaled = useLoginStore((state) => state.webauthnEnabled);
  const setShowSuccessResetPasswordBanner = useLoginStore((state) => state.setShowSuccessResetPasswordBanner);

  const [showIncorrectLoginError, setShowIncorrectLoginError] = useState(false);
  const [showGeneralError, setShowGeneralError] = useState(false);
  const setShowSpinnerOverlay = useLoginStore((state) => state.setShowSpinnerOverlay);
  const [passwordHidden, setPasswordHidden] = useState(!firstTimeLogin);
  const [passwordEye, setPasswordEye] = useState(PasswordEyeHidden);
  const [showBiometricsNotSupportedError, setShowBiometricsNotSupportedError] = useState(false);
  const [showErrorCollectingBiometricKey, setShowErrorCollectingBiometricKey] = useState(false);
  const [showIncorrectWebauthnError, setShowIncorrectWebauthnError] = useState(false);
  const [showNoCredentialsRegistered, setShowNoCredentialsRegistered] = useState(false);
  const [showErrorRedirectingBanner, setShowErrorRedirectingBanner] = useState(false);
  const [passwordEyeAriaLabel, setPasswordEyeAriaLabel] = useState(PASSWORD_HIDDEN_ARIA);
  const [showEnterUsernameError, setShowEnterUsernameError] = useState(false);
  const webauthnIcons = useWebauthnIcons();
  const webauthnRegisteredOnDevice = useLoginStore((state) => state.webauthnRegisteredOnDevice);
  const { userPoolId: failoverPoolId, isFailover } = useFailoverStore().failoverPoolConfig;
  
  const customerConfig: CustomerConfig = getCustomerConfig();
  const webauthnStrings = useWebauthnStrings();

  useEffect(() => {
    if (errorRedirecting) {
      setShowErrorRedirectingBanner(true);
    }
  }, [errorRedirecting])

  const clearBanners = () => {
    setShowBiometricsNotSupportedError(false);
    setShowEnterUsernameError(false);
    setShowErrorCollectingBiometricKey(false);
    setShowErrorRedirectingBanner(false);
    setShowGeneralError(false);
    setShowIncorrectLoginError(false);
    setShowIncorrectWebauthnError(false);
    setShowNoCredentialsRegistered(false);
    setShowSuccessResetPasswordBanner(false);
  }

  const onClickSubmitPassword = async () => {
    clearBanners();
    setShowSpinnerOverlay(true);
    try {
      if (isFailover && failoverPoolId != "") {
        setUsername(username);
        setLoginFormState(LOGIN_FORM_STATES.FAILOVER_EMAIL_OTP);
      } else {
        const intermediateUser = await Auth.signIn(username, password);
        setIntermediateUser(intermediateUser);

        if (intermediateUser.challengeName === COGNITO_SMS_MFA_CHALLENGE_LABEL) {
          setLoginFormState(LOGIN_FORM_STATES.MFA_STATE);
        } else if (
          intermediateUser.challengeName ===
          COGNITO_NEW_PASSWORD_REQUIRED_CHALLENGE_LABEL
        ) {
          setLoginFormState(LOGIN_FORM_STATES.NEW_PASSWORD_FIRST_LOGIN_STATE);
        } else if (promptRegisterWebauthn) {
            setLoginFormState(LOGIN_FORM_STATES.BIOMETRICS_STATE);
        } else {
          setCognitoUser(intermediateUser);
          setLoginFormState(LOGIN_FORM_STATES.SPINNER);
        }
      }
    } catch (error) {
      const authError = error as Error;
      if (authError.message === PASSWORD_ATTEMPTS_EXCEEDED) {
        setLoginFormState(LOGIN_FORM_STATES.TOO_MANY_FAILED_AUTH);
      } else if (authError.message.includes(INCORRECT_USERNAME_OR_PASSWORD)) {
        setShowIncorrectLoginError(true);
      } else {
        setShowGeneralError(true);
      }
    } finally {
      setShowSpinnerOverlay(false);
    }
  };

  const onClickForgotPassword = async () => {
    clearBanners();
    try {
      await Auth.forgotPassword(username);
      setShowSpinnerOverlay(false);
      setLoginFormState(LOGIN_FORM_STATES.FORGOT_PASSWORD_STATE);
    } catch (error) {
      const authError = error as Error;
      if (authError.name === LIMIT_EXCEEDED_EXCEPTION) {
        setLoginFormState(LOGIN_FORM_STATES.TOO_MANY_FAILED_AUTH);
      } else if (authError.message === 'Username cannot be empty') {
        setShowEnterUsernameError(true);
      } else {
        setShowGeneralError(true);
      }
    }
  };

  const onClickBiometricButton = async () => {
    clearBanners();
    if (!window.PublicKeyCredential) { // Client not capable of webauthn
      setShowBiometricsNotSupportedError(true);
    }
    // call webauthn auth apis
    setShowSpinnerOverlay(true);
    const hostName = window.location.hostname;
    try {
      const clientMetadata = {
        preferredAuthMethod: 'WebAuthN',
        executionContextId: crypto.randomUUID(),
        relyingPartyId: hostName,
      };
      console.log("executionContextId=" + clientMetadata.executionContextId);
      const intermediateUser: ChallengeUser = await Auth.signIn(username, undefined, clientMetadata);
      const answer = await GetWebauthnChallengeAnswer(intermediateUser, ['internal']);
      const result = await Auth.sendCustomChallengeAnswer(intermediateUser, answer, clientMetadata);
      if (result instanceof CognitoUser) {
        setCognitoUser(result as IdPrismUser);
        setLoginFormState(LOGIN_FORM_STATES.SPINNER);
      } else {
        console.log(result);
        setShowIncorrectWebauthnError(true);
      }
    } catch (error) {
        const authError = error as Error;
        console.log('Auth Error:', authError);
        if (authError.name === FAILED_TO_GET_WEBAUTHN_CHALLENGE_ANSWER) {
          setShowErrorCollectingBiometricKey(true);
        } else if ((authError.name === NOT_ALLOWED_ERROR && !webauthnRegisteredOnDevice) || authError.message === 'Incorrect username or password.') {
          setShowNoCredentialsRegistered(true);
        } else if (authError.message === 'Username cannot be empty') {
          setShowEnterUsernameError(true);
        } else {
          setShowGeneralError(true);
        }
    } finally {
        setShowSpinnerOverlay(false);
    }
  }

  const onClickNotReceivingPassword = async () => {
    window.location.replace(`${A2Z_WELCOME_URI}?username=${encodeURIComponent(username)}&triggerEmail=true`)    
  };

  const tWrapper = (key: string) => {
    if (firstTimeLogin) {
      key = key + "FirstTime";
    }
    return t(key);
  }

  const obfuscateEmail = (email: string) => {
    const emailParts = email.split('@');
    if (emailParts.length < 2) {
      return t('resources:yourRegisteredEmail');
    } else {
      return "****" + emailParts[0].substring(emailParts[0].length - 2) + '@' + emailParts[1];
    }
  }

  useEffect(() => {
    if (passwordHidden) {
      setPasswordEyeAriaLabel(PASSWORD_HIDDEN_ARIA);
      setPasswordEye(PasswordEyeHidden);
    } else {
      setPasswordEyeAriaLabel(PASSWORD_UNHIDDEN_ARIA);
      setPasswordEye(PasswordEyeUnhidden);
    }
  }, [passwordHidden])

  useEffect(() => {
    if (webauthnRegisteredOnDevice) {
      onClickBiometricButton();
    }
  }, [webauthnRegisteredOnDevice]);

  return (
    <>
    {showErrorRedirectingBanner &&
        <MessageBanner
          isDismissible
          onDismissed={() => setShowErrorRedirectingBanner(false)}
          type={MessageBannerType.Error}
        >
          {t('resources:errorRedirecting')}
        </MessageBanner >
      }
      <H3 id='login-step-header'>{tWrapper('resources:logIn')}</H3>
      {firstTimeLogin && (<P>{t('resources:passwordSubTitleFirstTime')} {obfuscateEmail(username)}</P>)}
      <Col gridGap='S200'>
        {showNoCredentialsRegistered && (
          <InputFooter autoFocus={true} id='input-footer-1' error>
            {t('resources:noWebauthnRegistered', webauthnStrings)}
          </InputFooter>
        )}
        {showIncorrectLoginError && (
          <InputFooter autoFocus={true} id='input-footer-2' error>
            {t('resources:loginError')}
          </InputFooter>
        )}
        {!firstTimeLogin && (
          <>
            <Label 
              color={showIncorrectLoginError? 'red70' : 'default'}
              htmlFor='input-id-1'>{t('resources:logInLabel')}</Label>
            <Input
              data-testid="username-input"
              id='input-id-1'
              autoComplete='current-username'
              placeholder={t('resources:exampleEmailPrompt') as string}
              value={username}
              type='text'
              autoCapitalize='none'
              autoCorrect='off'
              error={showIncorrectLoginError}
              onChange={(event) => setUsername(event.target.value)}
              required
            />
          </>
        )}
        <Col gridGap='S200'>
          {(!isFailover) && <Label 
            htmlFor='input-id-2'
            color={showIncorrectLoginError? 'red70' : 'default'}
          >{tWrapper('resources:password')}</Label>}
         {(!isFailover) && <Input
            data-testid="password-input"
            id='input-id-2'
            name='password'
            type={passwordHidden ? 'password' : 'text'}
            autoComplete='current-password'
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            error={showIncorrectLoginError}
            insetElementTrailing={{
              element: firstTimeLogin ? undefined : <img width='auto' aria-label={t(passwordEyeAriaLabel) as string} src={passwordEye} alt='ShowPasswordButton' onClick={() => setPasswordHidden(!passwordHidden)}/>
            }}
          />}
          {showIncorrectWebauthnError && (
            <InputFooter id='input-footer-3' error>
              {t('resources:incorrectWebauthn')}
            </InputFooter>
          )}
          {showGeneralError && (
            <InputFooter id='input-footer-4' error>
              {t('resources:generalError')}
            </InputFooter>
          )}
          {showBiometricsNotSupportedError && (
            <InputFooter id='input-footer-5' error>
              {t('resources:webauthnNotSupported', webauthnStrings)}
            </InputFooter>
          )}
          {showEnterUsernameError && (
            <InputFooter id='input-footer-5' error>
              {t('resources:enterUsernameError')}
            </InputFooter>
          )}
          {showErrorCollectingBiometricKey && (
                <Row
                    gridGap='S200'
                    justifyContent='center'
                    alignItems='center' hidden={showErrorCollectingBiometricKey}>
                    <img width='auto' src={ExclamationCircle} alt='ExclamationCircle' />
                    <P fontSize="T200">{t('resources:errorCapturingBiometrics', webauthnStrings)}</P>
                </Row>)}
        </Col >
        {!firstTimeLogin && (
        <Row gridGap='S200' alignItems='center' justifyContent='space-between' style={{ marginTop: 10}}>
          {!isFailover && <Link
            onClick={onClickForgotPassword}
            style={{ textDecoration: 'none' }}
            role='link'
            data-testid="forgot-password-link"
          >
            {t('resources:forgotPassword')}
          </Link>}
          {webauthnEnbaled && <img width='20%' aria-label={t('resources:useBiometricToLogIn', webauthnStrings) as string} src={webauthnIcons} onClick={onClickBiometricButton} alt='BiometricsLink' role='button'/>}
        </Row>
        )}
        {firstTimeLogin && (<Link
          onClick={onClickNotReceivingPassword}
          style={{ marginTop: 10, textDecoration: 'none' }}
        >
          {t('resources:notReceivingTempPassword')}
        </Link>)}
        <Button
          data-testid="continue-password-button"
          disabled={!password && !isFailover || !username}
          onClick={onClickSubmitPassword}
          style={{ width: '100%', marginTop: '24px' }}
          variant={ButtonVariant.Primary}
        >
          {tWrapper('resources:continue')}
        </Button>
        {!firstTimeLogin && customerConfig.links && customerConfig.links.selfSignUp && <>
            <View display='flex' alignItems='center' style={{marginTop: '16px', marginBottom: '16px'}}>
              <View flex={4}><Hr color='neutral50' size='wide'/></View>
              <View flex={1}><Text color='neutral50' textAlign='center'>{t('resources:or')}</Text></View>
              <View flex={4}><Hr color='neutral50' size='wide'/></View>
            </View>
            <Button
              data-testid="create-an-account-button"
              onClick={() => window.open(customerConfig.links!.selfSignUp, '_self')}
              variant={ButtonVariant.Secondary}
            >
              {t('resources:createAnAccount')}
            </Button>
          </>}
      </Col>
    </>
  );
}
